import { ApplicationRef, Injectable } from "@angular/core";

import { DashboardService, HomeResponse } from "@core/api/dashboard.service";

import { Observable, interval } from "rxjs";

import { AccessService } from "./access.service";
import { DataBaseService } from "./base/data-base-service";
import { ProctorService } from "./proctor.service";

@Injectable({
  providedIn: "root",
})
export class HomeActionsService extends DataBaseService<HomeResponse> {
  interval$?: Observable<number>;

  refresher(): Observable<HomeResponse> {
    return this.dash.getHome();
  }

  constructor(
    public appRef: ApplicationRef,
    public accessService: AccessService,
    public proctorService: ProctorService,
    public dash: DashboardService,
  ) {
    super(appRef, accessService, proctorService);
  }

  init(): void {
    this.interval$ = interval(1000 * 60 * 2);
    this.interval$.subscribe({
      next: () => {
        this.getData(true).subscribe();
      },
    });
  }
}
