import { Injectable } from "@angular/core";

import { DealOwnerProfile } from "@core/api-models/users.models";

import { ApiService } from "./api.service";

export interface HomeGuideStep {
  title: string;
  key: string;
  body: string;
  mark: string | null;
  link: string;
  icon: string;
  done: boolean;
  cta: string | null;
  cta_link: string | null;
}

export interface HomeGuideInfo {
  title: string;
  key: string;
  steps: HomeGuideStep[];
}

export interface HomeGuideStepRef {
  guide_key: string;
  step_key: string;
}

export interface HomeResponse {
  onboarding?: HomeGuideInfo | null;
  guides: HomeGuideInfo[];
  deal_owner: DealOwnerProfile | null;
}

@Injectable({
  providedIn: "root",
})
export class DashboardService extends ApiService {
  getHome() {
    return this.http.get<HomeResponse>(`${this.endpoint}/dashboard/home`);
  }

  markGuideStep(step: HomeGuideStepRef) {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/dashboard/home/mark_complete`,
      step,
    );
  }
}
